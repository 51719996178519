import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function Calendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([
    { id: 1, date: new Date(2023, 8, 15), title: 'Product Demo', time: '10:00 AM' },
    { id: 2, date: new Date(2023, 8, 18), title: 'Team Meeting', time: '2:00 PM' },
    { id: 3, date: new Date(2023, 8, 22), title: 'Client Call', time: '11:30 AM' },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', time: '' });

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const days = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: days }, (_, i) => new Date(year, month, i + 1));
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const prevMonth = () => setCurrentDate(addDays(currentDate, -30));
  const nextMonth = () => setCurrentDate(addDays(currentDate, 30));

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleAddEvent = () => {
    if (selectedDate && newEvent.title && newEvent.time) {
      setEvents([...events, { id: events.length + 1, date: selectedDate, ...newEvent }]);
      setIsModalOpen(false);
      setNewEvent({ title: '', time: '' });
    }
  };

  const daysInMonth = getDaysInMonth(currentDate);
  const firstDayOfMonth = daysInMonth[0].getDay();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Calendar</h1>
        <div className="flex items-center space-x-4">
          <button onClick={prevMonth} className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200">
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-medium">
            {MONTHS[currentDate.getMonth()]} {currentDate.getFullYear()}
          </span>
          <button onClick={nextMonth} className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200">
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="grid grid-cols-7 gap-px bg-gray-200">
          {DAYS.map(day => (
            <div key={day} className="bg-gray-50 p-2 text-center text-gray-600 font-medium">
              {day}
            </div>
          ))}
          {Array(firstDayOfMonth).fill(null).map((_, index) => (
            <div key={`empty-${index}`} className="bg-white p-2" />
          ))}
          {daysInMonth.map((date) => (
            <div
              key={date.toISOString()}
              className={`bg-white p-2 border-t border-l ${
                selectedDate && date.toDateString() === selectedDate.toDateString()
                  ? 'bg-blue-100'
                  : ''
              }`}
              onClick={() => handleDateClick(date)}
            >
              <div className="font-medium">{date.getDate()}</div>
              {events
                .filter(event => event.date.toDateString() === date.toDateString())
                .map(event => (
                  <div key={event.id} className="text-xs mt-1 p-1 bg-blue-500 text-white rounded">
                    {event.title}
                  </div>
                ))
              }
            </div>
          ))}
        </div>
      </div>
      {selectedDate && (
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold mb-4">
            Events for {selectedDate.toDateString()}
          </h2>
          <ul className="space-y-2">
            {events
              .filter(event => event.date.toDateString() === selectedDate.toDateString())
              .map(event => (
                <li key={event.id} className="flex justify-between items-center">
                  <span>{event.title}</span>
                  <span className="text-gray-500">{event.time}</span>
                </li>
              ))
            }
          </ul>
          <button
            onClick={() => setIsModalOpen(true)}
            className="mt-4 flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-150"
          >
            <Plus size={20} className="mr-2" /> Add Event
          </button>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Add New Event</h3>
                <div className="mb-4">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">Event Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={newEvent.title}
                    onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="time" className="block text-sm font-medium text-gray-700">Time</label>
                  <input
                    type="time"
                    name="time"
                    id="time"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={newEvent.time}
                    onChange={(e) => setNewEvent({ ...newEvent, time: e.target.value })}
                  />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleAddEvent}
                >
                  Add Event
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Calendar;