import React, { useState } from 'react';
import { Play, Pause, PhoneCall, User, Clock } from 'lucide-react';

const mockLiveSessions = [
  { id: 1, customer: 'Alice Johnson', agent: 'AI Agent 1', duration: '00:15:23', status: 'active' },
  { id: 2, customer: 'Bob Smith', agent: 'AI Agent 2', duration: '00:08:47', status: 'active' },
  { id: 3, customer: 'Charlie Brown', agent: 'AI Agent 3', duration: '00:22:01', status: 'paused' },
  { id: 4, customer: 'Diana Prince', agent: 'AI Agent 4', duration: '00:05:36', status: 'active' },
  { id: 5, customer: 'Ethan Hunt', agent: 'AI Agent 5', duration: '00:18:59', status: 'active' },
];

function LiveSessions() {
  const [sessions, setSessions] = useState(mockLiveSessions);

  const toggleSessionStatus = (id) => {
    setSessions(sessions.map(session => 
      session.id === id ? { ...session, status: session.status === 'active' ? 'paused' : 'active' } : session
    ));
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">Live Sessions</h1>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-700">Active VR Interactions</h2>
          <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
            {sessions.filter(s => s.status === 'active').length} Active
          </span>
        </div>
        <ul className="divide-y divide-gray-200">
          {sessions.map((session) => (
            <li key={session.id} className="p-4 hover:bg-gray-50 transition-colors duration-150">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className={`w-3 h-3 rounded-full ${session.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'}`}></div>
                  <div>
                    <p className="font-medium text-gray-900">{session.customer}</p>
                    <p className="text-sm text-gray-500 flex items-center">
                      <User size={14} className="mr-1" /> {session.agent}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <p className="text-sm text-gray-500 flex items-center">
                    <Clock size={14} className="mr-1" /> {session.duration}
                  </p>
                  <button 
                    onClick={() => toggleSessionStatus(session.id)}
                    className={`p-2 rounded-full ${session.status === 'active' ? 'bg-yellow-100 text-yellow-600' : 'bg-green-100 text-green-600'}`}
                  >
                    {session.status === 'active' ? <Pause size={20} /> : <Play size={20} />}
                  </button>
                  <button className="p-2 rounded-full bg-blue-100 text-blue-600">
                    <PhoneCall size={20} />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LiveSessions;