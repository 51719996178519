import React from 'react';

function Scheduled() {
  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">Scheduled Interactions</h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <p className="text-gray-600">Here you can view and manage scheduled interactions.</p>
        {/* Add your scheduled interactions content here */}
      </div>
    </div>
  );
}

export default Scheduled;