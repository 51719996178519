import React, { useState } from 'react';
import { Search, ChevronDown, ChevronUp, MessageCircle } from 'lucide-react';

const faqs = [
  {
    question: "How do I start a new AI-VR interaction?",
    answer: "To start a new AI-VR interaction, navigate to the 'Interactions' page and click on the 'New Interaction' button. Choose your preferred AI model and VR environment, then follow the on-screen instructions to begin."
  },
  {
    question: "Can I customize the VR environments?",
    answer: "Yes, you can customize VR environments. Go to the 'VR Environments' page, select the environment you want to modify, and use the editor tools to make changes. You can also create entirely new environments from scratch."
  },
  {
    question: "How do I train the AI models on my company's data?",
    answer: "To train AI models on your company's data, go to the 'AI Models' page and select the model you want to train. Click on 'Custom Training' and follow the wizard to upload your data and start the training process. This may take some time depending on the amount of data."
  },
  {
    question: "What types of analytics are available?",
    answer: "We offer a wide range of analytics including interaction metrics, user satisfaction scores, AI model performance, and VR environment usage. You can access these analytics from the 'Analytics' page and customize the dashboards to suit your needs."
  },
  {
    question: "How can I integrate this platform with my existing CRM?",
    answer: "We provide API integrations for most major CRM systems. Go to the 'Integrations' page in the settings, select your CRM from the list, and follow the step-by-step guide to set up the integration. If you need assistance, our support team is always ready to help."
  }
];

function Help() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedFaqs, setExpandedFaqs] = useState({});
  const [showChatbot, setShowChatbot] = useState(false);

  const toggleFaq = (index) => {
    setExpandedFaqs(prev => ({...prev, [index]: !prev[index]}));
  };

  const filteredFaqs = faqs.filter(faq => 
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">Help & Support</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="mb-6">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search for help"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-4">
          {filteredFaqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-4">
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => toggleFaq(index)}
              >
                <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                {expandedFaqs[index] ? (
                  <ChevronUp className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronDown className="h-5 w-5 text-gray-500" />
                )}
              </button>
              {expandedFaqs[index] && (
                <p className="mt-2 text-gray-600">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Contact Support</h2>
        <p className="text-gray-600 mb-4">
          Can't find what you're looking for? Our support team is here to help.
        </p>
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-gray-900">Email Support</h3>
            <p className="text-gray-600">support@aivr-platform.com</p>
          </div>
          <div>
            <h3 className="text-lg font-medium text-gray-900">Phone Support</h3>
            <p className="text-gray-600">+1 (888) 123-4567</p>
          </div>
          <div>
            <h3 className="text-lg font-medium text-gray-900">Live Chat</h3>
            <button
              onClick={() => setShowChatbot(!showChatbot)}
              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <MessageCircle className="mr-2 h-5 w-5" />
              {showChatbot ? 'Hide Chatbot' : 'Show Chatbot'}
            </button>
          </div>
        </div>
      </div>

      {showChatbot && (
        <div className="fixed bottom-4 right-4 w-80 h-96 bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="bg-indigo-600 text-white p-4 flex justify-between items-center">
            <h3 className="text-lg font-medium">Support Chat</h3>
            <button onClick={() => setShowChatbot(false)} className="text-white hover:text-gray-200">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-4 h-72 overflow-y-auto">
            {/* Chatbot messages would go here */}
            <p className="text-gray-600">Welcome to our support chat. How can we help you today?</p>
          </div>
          <div className="p-4 border-t border-gray-200">
            <input
              type="text"
              placeholder="Type your message..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
      )}

    </div>
  );
}

export default Help;