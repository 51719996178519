import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Interactions from './components/Interactions';
import LiveSessions from './components/LiveSessions';
import Scheduled from './components/Scheduled';
import Analytics from './components/Analytics';
import Reports from './components/Reports';
import VREnvironments from './components/VREnvironments';
import AIModels from './components/AIModels';
import Calendar from './components/Calendar';
import Settings from './components/Settings';
import Help from './components/Help';

function App() {
  return (
    <Router>
      <div className="flex h-screen bg-gray-50">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Header />
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50 p-6">
            <div className="container mx-auto">
              <div className="card content"> {/* Added 'content' class here */}
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/interactions" element={<Interactions />} />
                  <Route path="/live-sessions" element={<LiveSessions />} />
                  <Route path="/scheduled" element={<Scheduled />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/vr-environments" element={<VREnvironments />} />
                  <Route path="/ai-models" element={<AIModels />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/help" element={<Help />} />
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;