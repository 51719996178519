import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DashboardMetrics from './DashboardMetrics';  // Import the new component

// Simulated data - in a real app, this would come from your backend
const generateData = () => ({
  performanceData: [
    { name: 'Jan', interactions: 4000, satisfaction: 80, revenue: 24000 },
    { name: 'Feb', interactions: 3000, satisfaction: 78, revenue: 22100 },
    { name: 'Mar', interactions: 2000, satisfaction: 85, revenue: 22900 },
    { name: 'Apr', interactions: 2780, satisfaction: 82, revenue: 20000 },
    { name: 'May', interactions: 1890, satisfaction: 81, revenue: 21810 },
    { name: 'Jun', interactions: 2390, satisfaction: 88, revenue: 25000 },
    { name: 'Jul', interactions: 3490, satisfaction: 86, revenue: 21000 },
  ],
  interactionTypeData: [
    { name: 'Product Support', value: 400 },
    { name: 'Sales Inquiry', value: 300 },
    { name: 'Technical Issue', value: 200 },
    { name: 'Billing Question', value: 100 },
    { name: 'Feature Request', value: 50 },
  ],
  vrEnvironmentUsage: [
    { name: 'Modern Office', value: 45 },
    { name: 'Tech Hub', value: 30 },
    { name: 'Zen Garden', value: 15 },
    { name: 'Futuristic City', value: 10 },
  ],
  aiModelPerformance: [
    { name: 'GPT-4', accuracy: 95, speed: 90, satisfaction: 92 },
    { name: 'BERT', accuracy: 88, speed: 85, satisfaction: 86 },
    { name: 'Custom NLP', accuracy: 92, speed: 88, satisfaction: 90 },
    { name: 'Sentiment Analyzer', accuracy: 87, speed: 92, satisfaction: 85 },
  ],
});

function Dashboard() {
  const [data, setData] = useState(generateData());

  useEffect(() => {
    // Simulated real-time updates
    const interval = setInterval(() => {
      setData(generateData());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">AI-VR Interaction Dashboard</h1>
      
      <DashboardMetrics />  {/* Use the new DashboardMetrics component here */}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Performance Overview</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data.performanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="interactions" stroke="#8884d8" />
              <Line yAxisId="right" type="monotone" dataKey="satisfaction" stroke="#82ca9d" />
              <Line yAxisId="left" type="monotone" dataKey="revenue" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Interaction Types</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.interactionTypeData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {data.interactionTypeData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">VR Environment Usage</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data.vrEnvironmentUsage}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">AI Model Performance</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data.aiModelPerformance}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="accuracy" fill="#8884d8" />
              <Bar dataKey="speed" fill="#82ca9d" />
              <Bar dataKey="satisfaction" fill="#ffc658" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Interactions</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['Customer', 'Type', 'Duration', 'Satisfaction', 'AI Model', 'VR Environment', 'Status'].map((header) => (
                  <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[
                { customer: 'John Doe', type: 'Product Support', duration: '12m 30s', satisfaction: 4.5, aiModel: 'GPT-4', vrEnvironment: 'Modern Office', status: 'Completed' },
                { customer: 'Jane Smith', type: 'Sales Inquiry', duration: '8m 45s', satisfaction: 5, aiModel: 'Custom NLP', vrEnvironment: 'Tech Hub', status: 'Completed' },
                { customer: 'Bob Johnson', type: 'Technical Issue', duration: '22m 10s', satisfaction: 3.8, aiModel: 'BERT', vrEnvironment: 'Zen Garden', status: 'In Progress' },
              ].map((interaction, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.customer}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.type}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.duration}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.satisfaction}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.aiModel}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{interaction.vrEnvironment}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      interaction.status === 'Completed' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {interaction.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;