import React, { useState } from 'react';
import { Save, User, Building, Lock, Bell, Palette, Globe, Headphones, Sliders } from 'lucide-react';

const settingsSections = [
  { id: 'account', name: 'Account', icon: User },
  { id: 'company', name: 'Company', icon: Building },
  { id: 'security', name: 'Security', icon: Lock },
  { id: 'notifications', name: 'Notifications', icon: Bell },
  { id: 'appearance', name: 'Appearance', icon: Palette },
  { id: 'language', name: 'Language & Region', icon: Globe },
  { id: 'integrations', name: 'Integrations', icon: Sliders },
  { id: 'vr', name: 'VR Settings', icon: Headphones },
];

function Settings() {
  const [activeSection, setActiveSection] = useState('account');
  const [settings, setSettings] = useState({
    account: {
      firstName: 'John',
      lastName: 'Doe',
      email: 'john.doe@example.com',
      role: 'Admin',
    },
    company: {
      name: 'Acme Corp',
      industry: 'Technology',
      size: '100-500',
    },
    security: {
      twoFactorAuth: true,
      passwordLastChanged: '2023-05-15',
      sessionTimeout: 30,
    },
    notifications: {
      email: true,
      push: false,
      sms: false,
    },
    appearance: {
      theme: 'light',
      density: 'comfortable',
      fontSize: 'medium',
    },
    language: {
      preferred: 'English',
      timeZone: 'UTC',
      dateFormat: 'MM/DD/YYYY',
    },
    integrations: {
      crm: 'Salesforce',
      ticketing: 'Zendesk',
      analytics: 'Google Analytics',
    },
    vr: {
      defaultEnvironment: 'Modern Office',
      avatarStyle: 'Professional',
      interactionMode: 'Voice and Gesture',
    },
  });

  const handleChange = (section, field, value) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [section]: {
        ...prevSettings[section],
        [field]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Settings saved:', settings);
    // Here you would typically send the settings to your backend
    alert('Settings saved successfully!');
  };

  const renderSettingsSection = () => {
    switch (activeSection) {
      case 'account':
        return (
          <>
            <InputField label="First Name" value={settings.account.firstName} onChange={(value) => handleChange('account', 'firstName', value)} />
            <InputField label="Last Name" value={settings.account.lastName} onChange={(value) => handleChange('account', 'lastName', value)} />
            <InputField label="Email" type="email" value={settings.account.email} onChange={(value) => handleChange('account', 'email', value)} />
            <InputField label="Role" value={settings.account.role} disabled />
          </>
        );
      case 'company':
        return (
          <>
            <InputField label="Company Name" value={settings.company.name} onChange={(value) => handleChange('company', 'name', value)} />
            <InputField label="Industry" value={settings.company.industry} onChange={(value) => handleChange('company', 'industry', value)} />
            <SelectField label="Company Size" value={settings.company.size} onChange={(value) => handleChange('company', 'size', value)} options={['1-10', '11-50', '51-200', '201-500', '500+']} />
          </>
        );
      case 'security':
        return (
          <>
            <ToggleField label="Two-Factor Authentication" checked={settings.security.twoFactorAuth} onChange={(value) => handleChange('security', 'twoFactorAuth', value)} />
            <InputField label="Password Last Changed" type="date" value={settings.security.passwordLastChanged} onChange={(value) => handleChange('security', 'passwordLastChanged', value)} />
            <InputField label="Session Timeout (minutes)" type="number" value={settings.security.sessionTimeout} onChange={(value) => handleChange('security', 'sessionTimeout', parseInt(value))} />
          </>
        );
      case 'notifications':
        return (
          <>
            <ToggleField label="Email Notifications" checked={settings.notifications.email} onChange={(value) => handleChange('notifications', 'email', value)} />
            <ToggleField label="Push Notifications" checked={settings.notifications.push} onChange={(value) => handleChange('notifications', 'push', value)} />
            <ToggleField label="SMS Notifications" checked={settings.notifications.sms} onChange={(value) => handleChange('notifications', 'sms', value)} />
          </>
        );
      case 'appearance':
        return (
          <>
            <SelectField label="Theme" value={settings.appearance.theme} onChange={(value) => handleChange('appearance', 'theme', value)} options={['light', 'dark', 'auto']} />
            <SelectField label="Density" value={settings.appearance.density} onChange={(value) => handleChange('appearance', 'density', value)} options={['comfortable', 'compact']} />
            <SelectField label="Font Size" value={settings.appearance.fontSize} onChange={(value) => handleChange('appearance', 'fontSize', value)} options={['small', 'medium', 'large']} />
          </>
        );
      case 'language':
        return (
          <>
            <SelectField label="Preferred Language" value={settings.language.preferred} onChange={(value) => handleChange('language', 'preferred', value)} options={['English', 'Spanish', 'French', 'German', 'Chinese']} />
            <SelectField label="Time Zone" value={settings.language.timeZone} onChange={(value) => handleChange('language', 'timeZone', value)} options={['UTC', 'EST', 'CST', 'PST', 'JST']} />
            <SelectField label="Date Format" value={settings.language.dateFormat} onChange={(value) => handleChange('language', 'dateFormat', value)} options={['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD']} />
          </>
        );
      case 'integrations':
        return (
          <>
            <SelectField label="CRM Integration" value={settings.integrations.crm} onChange={(value) => handleChange('integrations', 'crm', value)} options={['None', 'Salesforce', 'HubSpot', 'Pipedrive']} />
            <SelectField label="Ticketing System" value={settings.integrations.ticketing} onChange={(value) => handleChange('integrations', 'ticketing', value)} options={['None', 'Zendesk', 'Freshdesk', 'Jira Service Desk']} />
            <SelectField label="Analytics Tool" value={settings.integrations.analytics} onChange={(value) => handleChange('integrations', 'analytics', value)} options={['None', 'Google Analytics', 'Mixpanel', 'Amplitude']} />
          </>
        );
      case 'vr':
        return (
          <>
            <SelectField label="Default VR Environment" value={settings.vr.defaultEnvironment} onChange={(value) => handleChange('vr', 'defaultEnvironment', value)} options={['Modern Office', 'Nature Retreat', 'Tech Hub', 'Custom']} />
            <SelectField label="Avatar Style" value={settings.vr.avatarStyle} onChange={(value) => handleChange('vr', 'avatarStyle', value)} options={['Professional', 'Casual', 'Futuristic', 'Custom']} />
            <SelectField label="Interaction Mode" value={settings.vr.interactionMode} onChange={(value) => handleChange('vr', 'interactionMode', value)} options={['Voice Only', 'Gesture Only', 'Voice and Gesture']} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-full bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <nav className="mt-5">
          <ul>
            {settingsSections.map((section) => (
              <li key={section.id}>
                <button
                  onClick={() => setActiveSection(section.id)}
                  className={`w-full flex items-center px-6 py-3 text-gray-600 hover:bg-gray-100 hover:text-gray-900 ${
                    activeSection === section.id ? 'bg-gray-100 text-gray-900' : ''
                  }`}
                >
                  <section.icon className="h-5 w-5 mr-3" />
                  {section.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="flex-1 p-10">
        <h1 className="text-2xl font-bold text-gray-800 mb-5">Settings</h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">{settingsSections.find(s => s.id === activeSection).name}</h2>
          <form onSubmit={handleSubmit}>
            {renderSettingsSection()}
            <div className="mt-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Save className="mr-2 h-5 w-5" />
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function InputField({ label, type = 'text', value, onChange, disabled = false }) {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
  );
}

function SelectField({ label, value, onChange, options }) {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}

function ToggleField({ label, checked, onChange }) {
  return (
    <div className="flex items-center justify-between mb-4">
      <span className="text-sm font-medium text-gray-700">{label}</span>
      <button
        type="button"
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        onClick={() => onChange(!checked)}
      >
        <span
          aria-hidden="true"
          className={`${
            checked ? 'translate-x-5' : 'translate-x-0'
          } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
        />
      </button>
    </div>
  );
}

export default Settings;