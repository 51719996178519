import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Settings, RotateCcw, Play, Plus } from 'lucide-react';

const initialModels = [
  { id: 1, name: 'GPT-4', version: '1.0', status: 'active', performance: 95 },
  { id: 2, name: 'BERT', version: '2.1', status: 'inactive', performance: 88 },
  { id: 3, name: 'Custom NLP', version: '3.2', status: 'active', performance: 92 },
  { id: 4, name: 'Sentiment Analyzer', version: '1.5', status: 'active', performance: 87 },
];

const performanceData = [
  { name: 'GPT-4', accuracy: 95, speed: 90, satisfaction: 92 },
  { name: 'BERT', accuracy: 88, speed: 85, satisfaction: 86 },
  { name: 'Custom NLP', accuracy: 92, speed: 88, satisfaction: 90 },
  { name: 'Sentiment Analyzer', accuracy: 87, speed: 92, satisfaction: 85 },
];

function AIModels() {
  const [models, setModels] = useState(initialModels);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newModel, setNewModel] = useState({ name: '', version: '', status: 'inactive' });

  const toggleModelStatus = (id) => {
    setModels(models.map(model => 
      model.id === id ? { ...model, status: model.status === 'active' ? 'inactive' : 'active' } : model
    ));
  };

  const handleAddModel = () => {
    if (newModel.name && newModel.version) {
      setModels([...models, { ...newModel, id: models.length + 1, performance: 0 }]);
      setIsModalOpen(false);
      setNewModel({ name: '', version: '', status: 'inactive' });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">AI Models</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-150 flex items-center"
        >
          <Plus size={20} className="mr-2" /> Add Model
        </button>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-700">Model Performance</h2>
          </div>
          <div className="p-4">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={performanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="accuracy" fill="#8884d8" />
                <Bar dataKey="speed" fill="#82ca9d" />
                <Bar dataKey="satisfaction" fill="#ffc658" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-700">Active Models</h2>
          </div>
          <ul className="divide-y divide-gray-200">
            {models.map((model) => (
              <li key={model.id} className="p-4 hover:bg-gray-50 transition-colors duration-150">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-medium text-gray-900">{model.name}</p>
                    <p className="text-sm text-gray-500">Version: {model.version}</p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      model.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {model.status}
                    </span>
                    <button 
                      onClick={() => toggleModelStatus(model.id)}
                      className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200"
                      title={model.status === 'active' ? 'Deactivate' : 'Activate'}
                    >
                      <Play size={20} />
                    </button>
                    <button 
                      className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200"
                      title="Retrain Model"
                    >
                      <RotateCcw size={20} />
                    </button>
                    <button 
                      className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200"
                      title="Model Settings"
                    >
                      <Settings size={20} />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-700">Model Training History</h2>
        </div>
        <div className="p-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Model</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Trained</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Performance Improvement</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {models.map((model) => (
                <tr key={model.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{model.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">2023-09-15</td>
                  <td className="px-6 py-4 whitespace-nowrap">4h 23m</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="text-green-600">+2.3%</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Add New AI Model</h3>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">Model Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={newModel.name}
                    onChange={(e) => setNewModel({ ...newModel, name: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="version" className="block text-sm font-medium text-gray-700">Version</label>
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    value={newModel.version}
                    onChange={(e) => setNewModel({ ...newModel, version: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                  <select
                    id="status"
                    name="status"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    value={newModel.status}
                    onChange={(e) => setNewModel({ ...newModel, status: e.target.value })}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleAddModel}
                >
                  Add Model
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AIModels;