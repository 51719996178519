import React from 'react';
import { Users, DollarSign, Star, ArrowUp, ArrowDown } from 'lucide-react';

const metrics = [
  {
    key: 'interactions',
    name: 'Total Interactions',
    icon: Users,
    value: '10,254',
    change: '+5.3%',
    subtext: 'Last 30 days',
    color: 'blue',
  },
  {
    key: 'revenue',
    name: 'Revenue',
    icon: DollarSign,
    value: '$52,389',
    change: '+10.8%',
    subtext: 'This month',
    color: 'green',
  },
  {
    key: 'satisfaction',
    name: 'Customer Satisfaction',
    icon: Star,
    value: '4.6/5',
    change: '+0.2',
    subtext: 'Based on 1,234 reviews',
    color: 'yellow',
  },
];

function DashboardMetrics() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {metrics.map((metric) => (
        <MetricCard key={metric.key} {...metric} />
      ))}
    </div>
  );
}

function MetricCard({ name, icon: Icon, value, change, subtext, color }) {
  const isPositive = change.startsWith('+');
  const ArrowIcon = isPositive ? ArrowUp : ArrowDown;
  const colorClasses = {
    blue: 'bg-blue-500 text-white',
    green: 'bg-green-500 text-white',
    yellow: 'bg-yellow-500 text-white',
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className={`p-6 ${colorClasses[color]}`}>
        <div className="flex items-center justify-between mb-4">
          <Icon size={32} />
          <div className={`flex items-center ${isPositive ? 'text-green-300' : 'text-red-300'}`}>
            <ArrowIcon size={20} />
            <span className="ml-1 text-lg font-semibold">{change}</span>
          </div>
        </div>
        <h3 className="text-3xl font-bold mb-2">{value}</h3>
        <p className="text-lg font-medium">{name}</p>
      </div>
      <div className="px-6 py-4">
        <p className="text-sm text-gray-600">{subtext}</p>
      </div>
    </div>
  );
}

export default DashboardMetrics;