import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const interactions = [
  { id: 1, customer: 'John Doe', date: '2023-09-18', duration: '15:32', satisfaction: 4.5 },
  { id: 2, customer: 'Jane Smith', date: '2023-09-17', duration: '08:45', satisfaction: 5.0 },
  { id: 3, customer: 'Bob Johnson', date: '2023-09-16', duration: '22:10', satisfaction: 3.8 },
  // Add more sample data as needed
];

function Interactions() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(interactions.length / itemsPerPage);

  const paginatedInteractions = interactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">AI-VR Interactions</h1>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {['ID', 'Customer', 'Date', 'Duration', 'Satisfaction'].map((header) => (
                <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {paginatedInteractions.map((interaction) => (
              <tr key={interaction.id}>
                <td className="px-6 py-4 whitespace-nowrap">{interaction.id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{interaction.customer}</td>
                <td className="px-6 py-4 whitespace-nowrap">{interaction.date}</td>
                <td className="px-6 py-4 whitespace-nowrap">{interaction.duration}</td>
                <td className="px-6 py-4 whitespace-nowrap">{interaction.satisfaction}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center">
        <button
          onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="flex items-center px-4 py-2 border rounded-md bg-white text-gray-700 hover:bg-gray-50"
        >
          <ChevronLeft size={20} />
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
          className="flex items-center px-4 py-2 border rounded-md bg-white text-gray-700 hover:bg-gray-50"
        >
          Next
          <ChevronRight size={20} />
        </button>
      </div>
    </div>
  );
}

export default Interactions;