import React, { useState } from 'react';
import { FileText, Download, Share2 } from 'lucide-react';

const reportTypes = [
  { id: 'interactions', name: 'Interactions Summary' },
  { id: 'performance', name: 'AI Performance Report' },
  { id: 'satisfaction', name: 'Customer Satisfaction' },
  { id: 'revenue', name: 'Revenue Analysis' },
  { id: 'vrUsage', name: 'VR Environment Usage' },
];

function Reports() {
  const [selectedReport, setSelectedReport] = useState(null);

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold text-gray-800">Reports</h1>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Generate Report</h2>
        <div className="flex space-x-4 mb-4">
          <select
            className="flex-grow appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={selectedReport || ''}
            onChange={(e) => setSelectedReport(e.target.value)}
          >
            <option value="">Select a report type</option>
            {reportTypes.map((type) => (
              <option key={type.id} value={type.id}>{type.name}</option>
            ))}
          </select>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-150 flex items-center"
            disabled={!selectedReport}
          >
            <FileText className="mr-2" size={20} />
            Generate
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Recent Reports</h2>
        <ul className="space-y-4">
          {reportTypes.map((report) => (
            <li key={report.id} className="flex items-center justify-between py-2 border-b">
              <div className="flex items-center">
                <FileText className="mr-2 text-gray-500" size={20} />
                <span>{report.name}</span>
              </div>
              <div className="flex space-x-2">
                <button className="p-2 text-gray-500 hover:text-blue-600">
                  <Download size={20} />
                </button>
                <button className="p-2 text-gray-500 hover:text-green-600">
                  <Share2 size={20} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Reports;