import React, { useState } from 'react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Calendar, Filter } from 'lucide-react';

const performanceData = [
  { date: '2023-01', interactions: 4000, satisfaction: 80, revenue: 24000 },
  { date: '2023-02', interactions: 3000, satisfaction: 78, revenue: 22100 },
  { date: '2023-03', interactions: 2000, satisfaction: 85, revenue: 22900 },
  { date: '2023-04', interactions: 2780, satisfaction: 82, revenue: 20000 },
  { date: '2023-05', interactions: 1890, satisfaction: 81, revenue: 21810 },
  { date: '2023-06', interactions: 2390, satisfaction: 88, revenue: 25000 },
  { date: '2023-07', interactions: 3490, satisfaction: 86, revenue: 21000 },
];

const interactionTypeData = [
  { name: 'Product Support', value: 400 },
  { name: 'Sales Inquiry', value: 300 },
  { name: 'Technical Issue', value: 200 },
  { name: 'Billing Question', value: 100 },
  { name: 'Feature Request', value: 50 },
];

const aiModelPerformance = [
  { name: 'GPT-4', accuracy: 95, speed: 90, satisfaction: 92 },
  { name: 'BERT', accuracy: 88, speed: 85, satisfaction: 86 },
  { name: 'Custom NLP', accuracy: 92, speed: 88, satisfaction: 90 },
  { name: 'Sentiment Analyzer', accuracy: 87, speed: 92, satisfaction: 85 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

function Analytics() {
  const [dateRange, setDateRange] = useState('last30days');
  const [filterType, setFilterType] = useState('all');

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-800">Analytics Dashboard</h1>
        <div className="flex space-x-4">
          <div className="relative">
            <select
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
            >
              <option value="last7days">Last 7 Days</option>
              <option value="last30days">Last 30 Days</option>
              <option value="lastQuarter">Last Quarter</option>
              <option value="lastYear">Last Year</option>
            </select>
            <Calendar className="absolute right-3 top-2 h-5 w-5 text-gray-400" />
          </div>
          <div className="relative">
            <select
              className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">All Interactions</option>
              <option value="productSupport">Product Support</option>
              <option value="salesInquiry">Sales Inquiry</option>
              <option value="technicalIssue">Technical Issue</option>
            </select>
            <Filter className="absolute right-3 top-2 h-5 w-5 text-gray-400" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Performance Overview</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={performanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line yAxisId="left" type="monotone" dataKey="interactions" stroke="#8884d8" />
              <Line yAxisId="right" type="monotone" dataKey="satisfaction" stroke="#82ca9d" />
              <Line yAxisId="left" type="monotone" dataKey="revenue" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Interaction Types</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={interactionTypeData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              >
                {interactionTypeData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">AI Model Performance</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={aiModelPerformance}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="accuracy" fill="#8884d8" />
            <Bar dataKey="speed" fill="#82ca9d" />
            <Bar dataKey="satisfaction" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Key Metrics</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <MetricCard title="Average Interaction Time" value="15m 23s" change="+2.5%" />
          <MetricCard title="Customer Satisfaction Score" value="4.7/5.0" change="+0.2" />
          <MetricCard title="Conversion Rate" value="6.8%" change="+0.5%" />
        </div>
      </div>
    </div>
  );
}

function MetricCard({ title, value, change }) {
  const isPositive = change.startsWith('+');
  return (
    <div className="bg-gray-100 rounded-lg p-4">
      <h3 className="text-lg font-semibold text-gray-700 mb-2">{title}</h3>
      <p className="text-3xl font-bold text-blue-600">{value}</p>
      <p className={`text-sm ${isPositive ? 'text-green-600' : 'text-red-600'}`}>{change}</p>
    </div>
  );
}

export default Analytics;