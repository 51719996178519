import React, { useState } from 'react';
import { Bell, User, ChevronDown } from 'lucide-react';

function Header() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-md p-4">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-secondary">Welcome, Admin</h2>
        <div className="flex items-center space-x-4">
          <button className="p-2 rounded-full hover:bg-background text-primary">
            <Bell size={20} />
          </button>
          <div className="relative">
            <button 
              className="flex items-center space-x-2 p-2 rounded-full hover:bg-background text-secondary"
              onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            >
              <User size={20} />
              <span>Admin</span>
              <ChevronDown size={16} />
            </button>
            {isUserMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1">
                <a href="#profile" className="block px-4 py-2 text-sm text-secondary hover:bg-background">Your Profile</a>
                <a href="#settings" className="block px-4 py-2 text-sm text-secondary hover:bg-background">Settings</a>
                <a href="#signout" className="block px-4 py-2 text-sm text-secondary hover:bg-background">Sign out</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;