import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Home, Users, BarChart2, Settings, Headphones, Calendar, MessageSquare, HelpCircle, ChevronDown, ChevronRight, FileText } from 'lucide-react';

const navItems = [
  { path: "/", name: "Dashboard", icon: Home },
  { 
    name: "Customer Interactions", 
    icon: Users, 
    subItems: [
      { path: "/interactions", name: "All Interactions" },
      { path: "/live-sessions", name: "Live Sessions" },
      { path: "/scheduled", name: "Scheduled" },
    ]
  },
  { path: "/analytics", name: "Analytics", icon: BarChart2 },
  { path: "/reports", name: "Reports", icon: FileText },
  { path: "/vr-environments", name: "VR Environments", icon: Headphones },
  { path: "/ai-models", name: "AI Models", icon: MessageSquare },
  { path: "/calendar", name: "Calendar", icon: Calendar },
  { path: "/settings", name: "Settings", icon: Settings },
  { path: "/help", name: "Help & Support", icon: HelpCircle },
];

function Sidebar() {
  const [openSubMenu, setOpenSubMenu] = useState(null);

  return (
    <aside className="bg-[#07458b] text-white w-64 min-h-screen p-4">
      <div className="logo mb-8">
        <h1 className="text-2xl font-bold">VRAI Platform</h1>
      </div>
      <nav>
        <ul className="space-y-2">
          {navItems.map((item, index) => (
            <li key={item.name}>
              {item.subItems ? (
                <div>
                  <button
                    onClick={() => setOpenSubMenu(openSubMenu === index ? null : index)}
                    className="flex items-center justify-between w-full p-2 rounded-lg text-white hover:bg-[#4a90e2] transition-colors duration-200"
                  >
                    <div className="flex items-center">
                      <item.icon size={20} className="mr-2" />
                      <span>{item.name}</span>
                    </div>
                    {openSubMenu === index ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                  </button>
                  {openSubMenu === index && (
                    <ul className="ml-6 mt-2 space-y-2">
                      {item.subItems.map((subItem) => (
                        <li key={subItem.path}>
                          <NavLink
                            to={subItem.path}
                            className={({ isActive }) =>
                              `block p-2 rounded-lg text-sm ${
                                isActive ? 'bg-[#4a90e2] text-white' : 'text-white hover:bg-[#4a90e2]'
                              }`
                            }
                          >
                            {subItem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center space-x-2 p-2 rounded-lg transition-colors duration-200 ${
                      isActive ? 'bg-[#4a90e2] text-white' : 'text-white hover:bg-[#4a90e2]'
                    }`
                  }
                >
                  <item.icon size={20} />
                  <span>{item.name}</span>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;